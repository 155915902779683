import React, { useState } from 'react'
import {
  CAvatar,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react'
import { cilAccountLogout, cilUser } from '@coreui/icons'
import CIcon from '@coreui/icons-react'
import { useContext } from 'react'
import { Context } from 'src/context/UserContext'
import { useSelector } from 'react-redux'

const AppHeaderDropdown = () => {
  const [initials] = useState(localStorage.getItem('initials') || "'")
  const email = useSelector((state) => state.auth.email)
  const { logout, toggleModal } = useContext(Context)

  return (
      <CDropdown variant="nav-item">
        <CDropdownToggle placement="bottom-end" className="py-0" caret={false}>
          <CAvatar color="primary" textColor="white">
            {initials}
          </CAvatar>
        </CDropdownToggle>
        <CDropdownMenu className="pt-0">
          <CDropdownHeader>{email}</CDropdownHeader>
          <CDropdownItem className="logout" onClick={toggleModal}>
            <CIcon icon={cilUser} className="me-2" />
            Perfil
          </CDropdownItem>
          <CDropdownItem className="logout" onClick={logout}>
            <CIcon icon={cilAccountLogout} className="me-2" />
            Sair
          </CDropdownItem>
        </CDropdownMenu>
      </CDropdown>
  )
}

export default AppHeaderDropdown
