import { createSlice } from '@reduxjs/toolkit';

const sidebarSlice = createSlice({
  name: 'sidebarShow',
  initialState: { sidebarShow: true },
  reducers: {
    setSideBarShow: (state, action) => {
        state.sidebarShow = action.payload;
    },
  },
});

export const { setSideBarShow } = sidebarSlice.actions;
export default sidebarSlice.reducer;
