import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from 'src/utils/api';

export const fetchUserInfo = createAsyncThunk('auth/fetchUserInfo', async (_, { getState, dispatch }) => {
  dispatch(setFetchingInfo(true));
  try {
    const token = getState().auth.token;

    if (token) {
      const response = await api.get('sessions/validate', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    }
  } catch (error) {
    console.error('Erro ao obter informações do usuário:', error);
    dispatch(clearAuthToken());
    throw error;
  } finally {
    dispatch(setFetchingInfo(false));
  }
});

const authSlice = createSlice({
  name: 'auth',
  initialState: { 
    token: null,
    isAdmin: false,
    user: null,
    email: null,
    fetchingInfo: false,
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.token = action.payload;
    },
    clearAuthToken: (state) => {
      state.token = null;
      state.isAdmin = false;
      state.user = null;

      localStorage.removeItem('token');

      api.defaults.headers.Authorization = undefined;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setFetchingInfo: (state, action) => {
      state.fetchingInfo = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserInfo.fulfilled, (state, action) => {
      state.fetchingInfo = false;
      state.isAdmin = action.payload.isAdmin;
      state.user = action.payload;
      state.email = action.payload.email
    });
  }
});

export const { setAuthToken, clearAuthToken, setIsAdmin, setEmail, setFetchingInfo } = authSlice.actions;
export default authSlice.reducer;
