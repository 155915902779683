export const logoNegative = [
  '608 134',
  `
  <title>Zeeps</title>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  width="100%" viewBox="0 0 903 316" enable-background="new 0 0 903 316" xml:space="preserve">
<path fill="none" opacity="1.000000" stroke="none" 
 d="
M592.000000,317.000000 
 C394.666656,317.000000 197.833328,317.000000 1.000000,317.000000 
 C1.000000,211.666672 1.000000,106.333336 1.000000,1.000000 
 C302.000000,1.000000 603.000000,1.000000 904.000000,1.000000 
 C904.000000,106.333336 904.000000,211.666672 904.000000,317.000000 
 C800.166687,317.000000 696.333313,317.000000 592.000000,317.000000 
M290.000000,209.500000 
 C290.000000,168.670181 290.039612,127.840248 289.933014,87.010712 
 C289.923340,83.308487 289.239868,79.547958 288.412842,75.918510 
 C285.167999,61.678574 278.172638,49.809822 266.884399,40.214291 
 C255.959412,30.927567 243.400177,25.367407 229.456238,25.205500 
 C182.799789,24.663757 136.133057,24.966192 89.470543,25.067556 
 C85.938187,25.075232 82.358093,25.822222 78.885239,26.603569 
 C63.219009,30.128269 50.515427,38.399265 41.187733,51.424713 
 C33.832882,61.695240 29.021954,73.077057 29.012911,85.999825 
 C28.980143,132.829193 28.999781,179.658600 29.001001,226.487991 
 C29.001040,227.987762 28.797356,229.526611 29.057346,230.981323 
 C31.925135,247.027420 39.231197,260.762482 51.810165,271.132965 
 C62.454952,279.908844 74.784088,285.864014 88.853828,285.908997 
 C153.514404,286.115631 218.175995,286.000031 282.837219,285.999939 
 C289.998322,285.999939 289.999573,285.999664 289.999786,278.494263 
 C290.000427,255.829514 290.000000,233.164749 290.000000,209.500000 
M756.528564,207.981506 
 C758.835510,204.679428 761.724548,201.639389 763.349121,198.030182 
 C770.600769,181.919983 772.203735,164.782852 768.695129,147.784973 
 C763.415222,122.205872 747.239563,112.003471 724.440735,110.303520 
 C704.697205,108.831375 684.699890,109.192810 665.247253,114.493233 
 C665.247253,163.896088 665.247253,213.298950 665.247253,262.752136 
 C674.899353,262.752136 684.302490,262.752136 694.025879,262.752136 
 C694.025879,247.731903 694.025879,232.955215 694.025879,219.321762 
 C704.077209,220.229355 713.890137,221.903549 723.684387,221.800507 
 C735.948181,221.671448 747.122864,217.514236 756.528564,207.981506 
M501.126923,221.967789 
 C505.623810,221.311600 510.171509,220.880402 514.590942,219.881531 
 C516.216309,219.514191 518.731567,217.876724 518.802063,216.696823 
 C519.206726,209.920563 518.998962,203.107712 518.998962,195.936264 
 C514.899292,196.378586 511.188202,196.599167 507.541016,197.204712 
 C494.186951,199.421738 480.800171,200.314713 467.595551,196.759155 
 C457.652008,194.081726 450.164703,186.352463 451.565186,176.004822 
 C475.832916,176.004822 500.132263,176.004822 524.851685,176.004822 
 C525.247437,172.666946 526.071838,169.470062 525.911682,166.323318 
 C525.512756,158.486588 525.428589,150.512970 523.831787,142.884491 
 C520.947998,129.107620 513.012695,119.124352 499.477386,113.780281 
 C486.469910,108.644615 473.197449,109.245781 459.842773,111.045433 
 C443.337128,113.269676 431.994415,122.267021 425.978424,137.881042 
 C420.495575,152.111298 420.999329,166.825256 423.147278,181.451248 
 C424.648163,191.671280 429.018860,201.148895 436.813446,208.097366 
 C447.745422,217.842529 461.238403,221.603912 475.684937,221.963272 
 C483.846100,222.166275 492.016449,221.998535 501.126923,221.967789 
M551.471130,202.983292 
 C559.045288,212.462250 568.936768,218.220779 580.829651,219.900879 
 C589.727600,221.157852 598.777832,221.860046 607.759521,221.853699 
 C616.752014,221.847336 625.755615,220.770355 634.721436,219.843948 
 C635.913269,219.720825 637.800354,217.762024 637.852539,216.586212 
 C638.153442,209.806854 637.999207,203.007294 637.999207,195.970795 
 C633.988953,196.354889 630.401062,196.459824 626.903259,197.076035 
 C613.737976,199.395386 600.629456,199.998688 587.454956,197.027313 
 C576.877319,194.641647 568.501953,183.963058 570.601196,176.007629 
 C594.832520,176.007629 619.121887,176.007629 643.693970,176.007629 
 C646.252380,164.287872 645.633972,152.710236 642.641479,141.483017 
 C638.836853,127.209290 630.132446,117.159355 615.383118,112.652664 
 C603.263855,108.949600 591.156372,109.465828 578.930481,111.020332 
 C564.581238,112.844826 553.826294,120.227036 547.069153,132.944183 
 C541.321777,143.760971 540.765686,155.624420 540.936279,167.627579 
 C541.115112,180.208084 543.499390,192.019821 551.471130,202.983292 
M821.953979,133.020035 
 C822.453796,133.013397 822.966980,133.078842 823.451538,132.989517 
 C834.254456,130.997681 844.991821,131.738724 855.627319,134.042862 
 C862.015015,135.426727 868.304260,137.265167 874.999329,138.992599 
 C874.999329,131.641159 875.116211,124.492256 874.880615,117.355026 
 C874.842773,116.207787 873.409668,114.374077 872.319519,114.111458 
 C864.492981,112.225952 856.645935,109.718445 848.694336,109.235291 
 C838.345215,108.606453 827.836121,109.096977 817.499634,110.136749 
 C802.951477,111.600189 788.486511,118.633720 785.282410,137.658539 
 C783.750061,146.757202 784.886719,156.043549 789.212524,164.329422 
 C794.317993,174.108704 803.844177,177.160980 813.976318,177.877411 
 C822.430115,178.475189 830.960205,177.952957 839.455811,178.015076 
 C846.109192,178.063721 850.528015,181.226776 850.999146,186.105026 
 C851.753418,193.915009 849.302551,197.414398 841.761780,198.989014 
 C828.954590,201.663284 816.453064,199.155426 804.005615,196.468796 
 C799.425964,195.480331 794.957581,193.976303 790.000000,192.584335 
 C790.000000,199.967834 790.176025,206.456604 789.925903,212.928879 
 C789.800476,216.174515 790.810425,217.575882 794.070740,218.095413 
 C801.073181,219.211212 807.974243,221.006332 814.992981,221.967758 
 C830.524658,224.095276 846.138794,224.438141 860.990967,218.750290 
 C866.078796,216.801819 870.436035,212.520264 874.605713,208.730927 
 C878.083862,205.570190 876.982178,200.842590 876.993774,196.645142 
 C877.015442,188.815460 877.156128,180.980591 876.902527,173.159058 
 C876.828735,170.882233 876.037415,168.350266 874.799438,166.437042 
 C868.978943,157.441971 859.734009,154.718842 849.832642,154.100662 
 C841.867371,153.603363 833.836609,154.227890 825.854553,153.890121 
 C822.567566,153.751053 819.001892,152.998230 816.156921,151.440094 
 C812.615540,149.500580 811.511414,145.537582 812.076538,141.626709 
 C812.805969,136.579132 817.105103,134.972076 821.953979,133.020035 
M366.984375,143.526352 
 C362.314392,149.187195 357.730743,154.922028 352.955688,160.492828 
 C344.209808,170.696259 335.293671,180.754044 326.572876,190.978638 
 C323.852539,194.168091 320.006989,197.433075 319.362091,201.131760 
 C318.190002,207.853806 319.029480,214.926620 319.029480,221.681808 
 C348.368500,221.681808 377.107452,221.681808 405.677521,221.681808 
 C405.677521,214.284653 405.677521,207.209351 405.677521,199.711792 
 C387.678955,199.711792 370.000488,199.711792 352.252777,199.711792 
 C354.681122,196.764694 356.830963,194.092133 359.047333,191.475891 
 C373.951111,173.882965 388.882385,156.313324 403.762421,138.700378 
 C405.310577,136.867859 407.659119,134.870392 407.789459,132.831375 
 C408.272522,125.276443 407.981812,117.672035 407.981812,110.347717 
 C378.572174,110.347717 349.832733,110.347717 321.351990,110.347717 
 C321.351990,118.108116 321.351990,125.518173 321.351990,133.354431 
 C339.696411,133.354431 357.690277,133.354431 375.897949,133.354431 
 C372.881561,136.816208 370.183777,139.912308 366.984375,143.526352 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
 d="
M290.000000,210.000000 
 C290.000000,233.164749 290.000427,255.829514 289.999786,278.494263 
 C289.999573,285.999664 289.998322,285.999939 282.837219,285.999939 
 C218.175995,286.000031 153.514404,286.115631 88.853828,285.908997 
 C74.784088,285.864014 62.454952,279.908844 51.810165,271.132965 
 C39.231197,260.762482 31.925135,247.027420 29.057346,230.981323 
 C28.797356,229.526611 29.001040,227.987762 29.001001,226.487991 
 C28.999781,179.658600 28.980143,132.829193 29.012911,85.999825 
 C29.021954,73.077057 33.832882,61.695240 41.187733,51.424713 
 C50.515427,38.399265 63.219009,30.128269 78.885239,26.603569 
 C82.358093,25.822222 85.938187,25.075232 89.470543,25.067556 
 C136.133057,24.966192 182.799789,24.663757 229.456238,25.205500 
 C243.400177,25.367407 255.959412,30.927567 266.884399,40.214291 
 C278.172638,49.809822 285.167999,61.678574 288.412842,75.918510 
 C289.239868,79.547958 289.923340,83.308487 289.933014,87.010712 
 C290.039612,127.840248 290.000000,168.670181 290.000000,210.000000 
M191.385986,154.000000 
 C193.172073,154.000000 194.958160,154.000000 197.396194,154.000000 
 C196.872330,150.768738 196.440857,148.087845 196.002747,145.408035 
 C192.562836,124.367668 182.885422,106.373077 168.404480,91.057648 
 C158.198822,80.263855 145.927048,72.047157 132.047607,66.599251 
 C120.567520,62.093143 108.701965,59.013329 96.213448,58.964588 
 C93.073624,58.952339 90.265862,60.004555 90.145081,63.113724 
 C89.786339,72.348373 90.016556,81.605896 90.016556,90.812340 
 C113.874405,91.911301 121.049683,93.827377 136.922562,105.060974 
 C152.576355,116.139526 161.429596,131.755569 164.888000,150.433960 
 C165.406586,153.234756 166.307388,154.070908 168.938904,154.034225 
 C176.095779,153.934418 183.254974,153.999985 191.385986,154.000000 
M131.033157,192.366806 
 C145.279877,220.882645 167.781067,239.709274 198.312714,248.501465 
 C206.178833,250.766678 214.655579,250.936600 222.863632,251.975632 
 C225.736420,252.339264 227.908096,251.159653 227.952972,248.040543 
 C228.081650,239.098541 227.998306,230.153488 227.998306,221.061279 
 C209.056168,220.557495 192.139206,215.459335 177.914658,203.343903 
 C163.673050,191.213974 155.739197,175.419907 152.764496,157.258392 
 C141.953613,157.258392 131.529129,157.258392 120.779663,157.258392 
 C122.071198,163.400955 122.934593,169.340057 124.625839,175.033264 
 C126.303902,180.682037 128.815506,186.083206 131.033157,192.366806 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
 d="
M756.267334,208.235504 
 C747.122864,217.514236 735.948181,221.671448 723.684387,221.800507 
 C713.890137,221.903549 704.077209,220.229355 694.025879,219.321762 
 C694.025879,232.955215 694.025879,247.731903 694.025879,262.752136 
 C684.302490,262.752136 674.899353,262.752136 665.247253,262.752136 
 C665.247253,213.298950 665.247253,163.896088 665.249023,114.492630 
 C684.699890,109.192810 704.697205,108.831375 724.440735,110.303520 
 C747.239563,112.003471 763.415222,122.205872 768.695129,147.784973 
 C772.203735,164.782852 770.600769,181.919983 763.349121,198.030182 
 C761.724548,201.639389 758.835510,204.679428 756.267334,208.235504 
M700.639832,195.001877 
 C704.358276,195.976318 708.026245,197.283081 711.805481,197.857224 
 C720.124451,199.121078 732.355042,195.454865 736.546875,186.275101 
 C740.965393,176.598831 741.889709,166.616989 740.766602,156.614395 
 C739.581848,146.062622 734.855469,136.544083 723.014709,134.750458 
 C714.417297,133.448120 705.497314,134.100876 696.732666,134.328354 
 C695.782410,134.353012 694.140381,137.334854 694.119385,138.961792 
 C693.919250,154.453934 694.249817,169.956024 693.856384,185.440750 
 C693.728943,190.456696 694.973816,193.564362 700.639832,195.001877 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
 d="
M500.654785,221.982788 
 C492.016449,221.998535 483.846100,222.166275 475.684937,221.963272 
 C461.238403,221.603912 447.745422,217.842529 436.813446,208.097366 
 C429.018860,201.148895 424.648163,191.671280 423.147278,181.451248 
 C420.999329,166.825256 420.495575,152.111298 425.978424,137.881042 
 C431.994415,122.267021 443.337128,113.269676 459.842773,111.045433 
 C473.197449,109.245781 486.469910,108.644615 499.477386,113.780281 
 C513.012695,119.124352 520.947998,129.107620 523.831787,142.884491 
 C525.428589,150.512970 525.512756,158.486588 525.911682,166.323318 
 C526.071838,169.470062 525.247437,172.666946 524.851685,176.004822 
 C500.132263,176.004822 475.832916,176.004822 451.565186,176.004822 
 C450.164703,186.352463 457.652008,194.081726 467.595551,196.759155 
 C480.800171,200.314713 494.186951,199.421738 507.541016,197.204712 
 C511.188202,196.599167 514.899292,196.378586 518.998962,195.936264 
 C518.998962,203.107712 519.206726,209.920563 518.802063,216.696823 
 C518.731567,217.876724 516.216309,219.514191 514.590942,219.881531 
 C510.171509,220.880402 505.623810,221.311600 500.654785,221.982788 
M492.364746,156.000000 
 C493.817413,156.000000 495.270111,156.000000 496.657867,156.000000 
 C498.504059,141.872116 489.884033,134.417557 480.367706,133.293579 
 C466.018677,131.598785 454.954285,133.516510 451.028107,154.374847 
 C452.304657,154.899673 453.602173,155.886337 454.909912,155.900177 
 C467.068604,156.028854 479.229034,155.990158 492.364746,156.000000 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
 d="
M551.216309,202.711014 
 C543.499390,192.019821 541.115112,180.208084 540.936279,167.627579 
 C540.765686,155.624420 541.321777,143.760971 547.069153,132.944183 
 C553.826294,120.227036 564.581238,112.844826 578.930481,111.020332 
 C591.156372,109.465828 603.263855,108.949600 615.383118,112.652664 
 C630.132446,117.159355 638.836853,127.209290 642.641479,141.483017 
 C645.633972,152.710236 646.252380,164.287872 643.693970,176.007629 
 C619.121887,176.007629 594.832520,176.007629 570.601196,176.007629 
 C568.501953,183.963058 576.877319,194.641647 587.454956,197.027313 
 C600.629456,199.998688 613.737976,199.395386 626.903259,197.076035 
 C630.401062,196.459824 633.988953,196.354889 637.999207,195.970795 
 C637.999207,203.007294 638.153442,209.806854 637.852539,216.586212 
 C637.800354,217.762024 635.913269,219.720825 634.721436,219.843948 
 C625.755615,220.770355 616.752014,221.847336 607.759521,221.853699 
 C598.777832,221.860046 589.727600,221.157852 580.829651,219.900879 
 C568.936768,218.220779 559.045288,212.462250 551.216309,202.711014 
M602.258484,133.993759 
 C585.113525,129.788315 570.239075,137.308716 570.583557,155.758286 
 C586.018738,155.758286 601.452026,155.758286 616.901062,155.758286 
 C615.908813,146.031708 613.866699,137.231873 602.258484,133.993759 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
 d="
M821.557739,133.165222 
 C817.105103,134.972076 812.805969,136.579132 812.076538,141.626709 
 C811.511414,145.537582 812.615540,149.500580 816.156921,151.440094 
 C819.001892,152.998230 822.567566,153.751053 825.854553,153.890121 
 C833.836609,154.227890 841.867371,153.603363 849.832642,154.100662 
 C859.734009,154.718842 868.978943,157.441971 874.799438,166.437042 
 C876.037415,168.350266 876.828735,170.882233 876.902527,173.159058 
 C877.156128,180.980591 877.015442,188.815460 876.993774,196.645142 
 C876.982178,200.842590 878.083862,205.570190 874.605713,208.730927 
 C870.436035,212.520264 866.078796,216.801819 860.990967,218.750290 
 C846.138794,224.438141 830.524658,224.095276 814.992981,221.967758 
 C807.974243,221.006332 801.073181,219.211212 794.070740,218.095413 
 C790.810425,217.575882 789.800476,216.174515 789.925903,212.928879 
 C790.176025,206.456604 790.000000,199.967834 790.000000,192.584335 
 C794.957581,193.976303 799.425964,195.480331 804.005615,196.468796 
 C816.453064,199.155426 828.954590,201.663284 841.761780,198.989014 
 C849.302551,197.414398 851.753418,193.915009 850.999146,186.105026 
 C850.528015,181.226776 846.109192,178.063721 839.455811,178.015076 
 C830.960205,177.952957 822.430115,178.475189 813.976318,177.877411 
 C803.844177,177.160980 794.317993,174.108704 789.212524,164.329422 
 C784.886719,156.043549 783.750061,146.757202 785.282410,137.658539 
 C788.486511,118.633720 802.951477,111.600189 817.499634,110.136749 
 C827.836121,109.096977 838.345215,108.606453 848.694336,109.235291 
 C856.645935,109.718445 864.492981,112.225952 872.319519,114.111458 
 C873.409668,114.374077 874.842773,116.207787 874.880615,117.355026 
 C875.116211,124.492256 874.999329,131.641159 874.999329,138.992599 
 C868.304260,137.265167 862.015015,135.426727 855.627319,134.042862 
 C844.991821,131.738724 834.254456,130.997681 823.451538,132.989517 
 C822.966980,133.078842 822.453796,133.013397 821.557739,133.165222 
z"/>
<path fill="#FFFFFF" opacity="1.000000" stroke="none" 
 d="
M367.235168,143.267395 
 C370.183777,139.912308 372.881561,136.816208 375.897949,133.354431 
 C357.690277,133.354431 339.696411,133.354431 321.351990,133.354431 
 C321.351990,125.518173 321.351990,118.108116 321.351990,110.347717 
 C349.832733,110.347717 378.572174,110.347717 407.981812,110.347717 
 C407.981812,117.672035 408.272522,125.276443 407.789459,132.831375 
 C407.659119,134.870392 405.310577,136.867859 403.762421,138.700378 
 C388.882385,156.313324 373.951111,173.882965 359.047333,191.475891 
 C356.830963,194.092133 354.681122,196.764694 352.252777,199.711792 
 C370.000488,199.711792 387.678955,199.711792 405.677521,199.711792 
 C405.677521,207.209351 405.677521,214.284653 405.677521,221.681808 
 C377.107452,221.681808 348.368500,221.681808 319.029480,221.681808 
 C319.029480,214.926620 318.190002,207.853806 319.362091,201.131760 
 C320.006989,197.433075 323.852539,194.168091 326.572876,190.978638 
 C335.293671,180.754044 344.209808,170.696259 352.955688,160.492828 
 C357.730743,154.922028 362.314392,149.187195 367.235168,143.267395 
z"/>
<path fill="none" opacity="1.000000" stroke="none" 
 d="
M190.899612,154.000000 
 C183.254974,153.999985 176.095779,153.934418 168.938904,154.034225 
 C166.307388,154.070908 165.406586,153.234756 164.888000,150.433960 
 C161.429596,131.755569 152.576355,116.139526 136.922562,105.060974 
 C121.049683,93.827377 113.874405,91.911301 90.016556,90.812340 
 C90.016556,81.605896 89.786339,72.348373 90.145081,63.113724 
 C90.265862,60.004555 93.073624,58.952339 96.213448,58.964588 
 C108.701965,59.013329 120.567520,62.093143 132.047607,66.599251 
 C145.927048,72.047157 158.198822,80.263855 168.404480,91.057648 
 C182.885422,106.373077 192.562836,124.367668 196.002747,145.408035 
 C196.440857,148.087845 196.872330,150.768738 197.396194,154.000000 
 C194.958160,154.000000 193.172073,154.000000 190.899612,154.000000 
z"/>
<path fill="none" opacity="1.000000" stroke="none" 
 d="
M130.997345,191.979889 
 C128.815506,186.083206 126.303902,180.682037 124.625839,175.033264 
 C122.934593,169.340057 122.071198,163.400955 120.779663,157.258392 
 C131.529129,157.258392 141.953613,157.258392 152.764496,157.258392 
 C155.739197,175.419907 163.673050,191.213974 177.914658,203.343903 
 C192.139206,215.459335 209.056168,220.557495 227.998306,221.061279 
 C227.998306,230.153488 228.081650,239.098541 227.952972,248.040543 
 C227.908096,251.159653 225.736420,252.339264 222.863632,251.975632 
 C214.655579,250.936600 206.178833,250.766678 198.312714,248.501465 
 C167.781067,239.709274 145.279877,220.882645 130.997345,191.979889 
z"/>
<path fill="none" opacity="1.000000" stroke="none" 
 d="
M700.225220,194.986572 
 C694.973816,193.564362 693.728943,190.456696 693.856384,185.440750 
 C694.249817,169.956024 693.919250,154.453934 694.119385,138.961792 
 C694.140381,137.334854 695.782410,134.353012 696.732666,134.328354 
 C705.497314,134.100876 714.417297,133.448120 723.014709,134.750458 
 C734.855469,136.544083 739.581848,146.062622 740.766602,156.614395 
 C741.889709,166.616989 740.965393,176.598831 736.546875,186.275101 
 C732.355042,195.454865 720.124451,199.121078 711.805481,197.857224 
 C708.026245,197.283081 704.358276,195.976318 700.225220,194.986572 
z"/>
<path fill="none" opacity="1.000000" stroke="none" 
 d="
M491.876862,156.000000 
 C479.229034,155.990158 467.068604,156.028854 454.909912,155.900177 
 C453.602173,155.886337 452.304657,154.899673 451.028107,154.374847 
 C454.954285,133.516510 466.018677,131.598785 480.367706,133.293579 
 C489.884033,134.417557 498.504059,141.872116 496.657867,156.000000 
 C495.270111,156.000000 493.817413,156.000000 491.876862,156.000000 
z"/>
<path fill="none" opacity="1.000000" stroke="none" 
 d="
M602.681396,134.007690 
 C613.866699,137.231873 615.908813,146.031708 616.901062,155.758286 
 C601.452026,155.758286 586.018738,155.758286 570.583557,155.758286 
 C570.239075,137.308716 585.113525,129.788315 602.681396,134.007690 
z"/>
</svg>
`,
]
