import React from 'react'
import CIcon from '@coreui/icons-react'
import { cibWhatsapp, cilApplications, cilChatBubble, cilEnvelopeClosed, cilFolderOpen, cilHome } from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const navigation = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Cadastros',
  },
  {
    component: CNavItem,
    name: 'Mensagens',
    to: '/mensagem',
    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Banco de Dados',
    to: '/bancodados',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'FTP',
    to: '/ftp',
    icon: <CIcon icon={cilFolderOpen} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Configurações',
  },
  {
    component: CNavItem,
    name: 'Números',
    to: '/phones',
    icon: <CIcon icon={cibWhatsapp} customClassName="nav-icon" />,
  },
]

export default navigation
