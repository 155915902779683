import React, { useEffect, useState } from 'react'
import { CFooter } from '@coreui/react'
import api from 'src/utils/api'

const AppFooter = () => {
  const [apiVersion, setApiVersion] = useState('')

  const clientVersion = process.env.REACT_APP_VERSION || ""

  useEffect(() => {
    api.get('/system/version')
      .then(response => {
        setApiVersion(response.data.version)
      })
  })
  return (
    <CFooter>
      <div>
        <a href="https://zeeps.com.br/" target="_blank" rel="noopener noreferrer">
          Zeeps
        </a>
        <span className="ms-1">&copy; {new Date().getFullYear().toString()} | Server {apiVersion} - Client {clientVersion}</span>
      </div>
    </CFooter>
  )
}

export default React.memo(AppFooter)
