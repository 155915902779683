import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilLibraryBuilding,
  cilPuzzle,
  cilUserPlus,
  cilApplications,
  cilEnvelopeClosed,
  cilHome,
  cilRss,
  cilFolderOpen,
  cibWhatsapp,
  cilChatBubble,
} from '@coreui/icons'
import { CNavItem, CNavTitle } from '@coreui/react'

const databaseIcon = [
  '24 24',
  `
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-database"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>
  `
];

const navigation_admin = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilHome} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Cadastros',
  },
  {
    component: CNavItem,
    name: 'Mensagens',
    to: '/mensagem',
    icon: <CIcon icon={cilEnvelopeClosed} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Integrações',
    to: '/integracao',
    icon: <CIcon icon={cilChatBubble} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'Banco de Dados',
    to: '/bancodados',
    icon: <CIcon icon={cilApplications} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'FTP',
    to: '/ftp',
    icon: <CIcon icon={cilFolderOpen} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'VPN',
    to: '/vpn',
    icon: <CIcon icon={cilRss} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Configurações',
  },
  {
    component: CNavItem,
    name: 'Números',
    to: '/phones',
    icon: <CIcon icon={cibWhatsapp} customClassName="nav-icon" />,
  },
  {
    component: CNavTitle,
    name: 'Administração',
  },
  {
    component: CNavItem,
    name: 'Empresas',
    icon: <CIcon icon={cilLibraryBuilding} customClassName="nav-icon" />,
    to: '/cadastros/empresas',
  },
  {
    component: CNavItem,
    name: 'Usuários',
    icon: <CIcon icon={cilUserPlus} customClassName="nav-icon" />,
    to: '/cadastros/users',
  },
  {
    component: CNavItem,
    name: 'APIs',
    icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
    to: '/cadastros/api',
  },
  {
    component: CNavItem,
    name: 'Query',
    icon: <CIcon icon={databaseIcon} customClassName="nav-icon" />,
    to: '/query',
  },
]

export default navigation_admin
