import React, { Component, Suspense } from 'react'
import {HashRouter, Route, Routes, Navigate } from 'react-router-dom'

import { ToastContainer } from 'react-toastify'
import { UserProvider } from './context/UserContext';
import { Login } from './components';

import Modal from 'react-modal';
import { createTheme, rgbToHex } from '@mui/material';
import { ThemeProvider } from 'styled-components';


import './scss/style.scss'
import 'react-toastify/dist/ReactToastify.css';
import 'rsuite/dist/rsuite.min.css';
import 'rsuite/Button/styles/index.css';
import '@xyflow/react/dist/style.css';

Modal.setAppElement('#root');

const loading = (
  <div className="flex pt-3 text-center justify-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ForgotPassword = React.lazy(() => import('src/components/ForgotPassword.js'))

const theme = createTheme({
  palette: {
    primary: {
      main: rgbToHex(getComputedStyle(document.documentElement).getPropertyValue('--cui-primary')),
    },
    secondary: {
      main: rgbToHex(getComputedStyle(document.documentElement).getPropertyValue('--cui-secondary')),
    },
  },
});

class App extends Component {
  render() {
    return (
      <HashRouter>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <Suspense fallback={loading}>
              <ToastContainer position='top-center' />
              <Routes>
                <Route path="/404" name="Page 404" element={<Page404 />} />
                <Route path="/500" name="Page 500" element={<Page500 />} />
                <Route path="/forgotyourpassword" name="Esqueceu sua senha?" element={<ForgotPassword />} />
                <Route path='/login' name='Login' element={<Login />} />
                <Route path="*" name="Home" element={<DefaultLayout />} />
              </Routes>
            </Suspense>
          </UserProvider>
        </ThemeProvider>
      </HashRouter>
    )
  }
}

export default App
