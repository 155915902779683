import { configureStore } from '@reduxjs/toolkit';
import authReducer from './authSlice';
import sidebarReducer from './sidebarSlice';
import loadingReducer from './loadingSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    sidebarShow: sidebarReducer,
    loading: loadingReducer,
  },
});

export default store;
