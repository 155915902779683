import React, { useContext, useEffect, useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import { Context } from '../context/UserContext'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const token = useSelector((state) => state.auth.token)
  const history = useNavigate()
  const [user, setUser] = useState({
    email: '',
    password: '',
    formErrors: { email: '', password: '' },
    emailValid: false,
    passwordValid: false,
    formValid: false,
  })
  const { login } = useContext(Context)

  function handleChange(e) {
    setUser({ ...user, [e.target.name]: e.target.value })
    validateField(e.target.name, e.target.value)
  }

  function validateField(fieldName, value) {
    let fieldValidationErrors = user.formErrors
    let emailValid = user.emailValid
    let passwordValid = user.passwordValid

    switch (fieldName) {
      case 'email':
        if(value === 'admin'){
          emailValid = true
        }else{
          emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
        }
        fieldValidationErrors.email = emailValid ? '' : ' E-mail inválido'
        if (emailValid === null) {
          emailValid = false
        } else {
          emailValid = true
        }
        break
      case 'password':
        passwordValid = true //TODO: fazer uma validação para senha
        fieldValidationErrors.password = passwordValid ? '' : ' Defina uma senha maior.'
        break
      default:
        break
    }
    setUser(
      (user) => (
        {
          ...user,
          formErrors: fieldValidationErrors,
          emailValid: emailValid,
          passwordValid: passwordValid,
        }
      ),
      validateForm()
    )
  }

  function validateForm() {
    setUser(
      (user) => (
        {
          ...user,
          formValid: user.emailValid && user.passwordValid
        }
      )
    )
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    login(user)
  }

  useEffect(() => {
    const email = document.getElementById('email')
    const password = document.getElementById('password')
    email.focus()

    validateField('email', email.value)
    validateField('password', password.value)
  }, [])

  useEffect(() => {
    if (token) {
      history('/')
    }
  }, [token, history])

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={4}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm noValidate validated={user.formValid} onSubmit={handleSubmit}>
                    <h1>Login</h1>
                    <p className="text-medium-emphasis">Entre com sua conta</p>
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput
                        placeholder="E-mail"
                        name="email"
                        type="email"
                        id='email'
                        feedback={user.formErrors.email}
                        valid={user.emailValid}
                        invalid={!user.emailValid}
                        autoComplete="email"
                        required
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        feedback={user.formErrors.password}
                        valid={user.passwordValid}
                        id='password'
                        invalid={!user.passwordValid}
                        placeholder="Senha"
                        name="password"
                        autoComplete='current-password'
                        required
                        onChange={handleChange}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton
                          color="primary"
                          disabled={!user.formValid}
                          type="submit"
                          className="px-4"
                          value="Entrar"
                        >
                          Entrar
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )
}

export default Login
