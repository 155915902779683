import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import useAuth from '../hooks/useAuth'
const Context = createContext()
function UserProvider({ children }) {
  const { authenticated, isAdmin, initials, loading, register, expired, login, logout, editPsw } = useAuth()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <Context.Provider
      value={{ loading, authenticated, register, login, logout, expired, isAdmin, initials, editPsw, isModalOpen, toggleModal }}
    >
      {children}
    </Context.Provider>
  )
}

UserProvider.propTypes = { children: PropTypes.node }
export { Context, UserProvider }
