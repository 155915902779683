import api from '../utils/api'

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import useFlashMessage from './useFlashMessage'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import { clearAuthToken, setAuthToken, setIsAdmin } from 'src/redux/authSlice'


export default function useAuth() {
  const dispatch = useDispatch()
  const [authenticated, setAuthenticated] = useState(false)
  const [initials, setInitials] = useState({})
  const [loading, setLoading] = useState(true)
  const history = useNavigate()
  const { setFlashMessage } = useFlashMessage()

  const isAdmin = useSelector((state) => state.auth.isAdmin)

  useEffect(() => {
    const token = localStorage.getItem('token')

    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`
      setAuthenticated(true)
      dispatch(setAuthToken(token))
    } else {
      setAuthenticated(false)
      dispatch(clearAuthToken())
    }

    setLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function register(user) {
    let msgText = 'Cadastro realizado com sucesso!'
    let msgType = 'success'

    try {
      const data = await api.post('/users/register', user).then((response) => {
        return response.data
      })

      await authUser(data)
    } catch (error) {
      // tratar erro
      msgText = error.response.data.message
      msgType = 'error'
    }

    setFlashMessage(msgText, msgType)
  }

  async function login(user) {
    try {
      await api.post('/sessions', user)
      .then(async (response) => {
        await authUser(response.data)
        toast.success('Login realizado com sucesso!')
      })
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message)
    }
  }

  async function authUser(data) {
    setAuthenticated(true)
    localStorage.setItem('token', data.token)
    dispatch(setAuthToken(data.token))
    document.cookie = `token=${data.token};`
    if (data.user.isAdmin) {
      dispatch(setIsAdmin(true))
      localStorage.setItem('isAdmin', 'true')
    } else {
      dispatch(setIsAdmin(false))
      localStorage.setItem('isAdmin', 'false')
    }

    const initials = data.user.name.split(' ').map((n) => n[0]).join('')

    setInitials(initials)
    localStorage.setItem('initials', initials)

    history('/dashboard')
  }

  function logout() {
    setAuthenticated(false)
    localStorage.removeItem('token')
    localStorage.removeItem('isAdmin')
    localStorage.removeItem('initials')
    api.defaults.headers.Authorization = undefined
    dispatch(clearAuthToken())
    dispatch(setIsAdmin(false))
    history('/login')
    toast.success('Logout realizado com sucesso!')
  }

  async function expired() {
    const token = localStorage.getItem('token')

    if (token) {
      const decodedJwt = token.split(".")[1];

      if (decodedJwt.exp * 1000 < Date.now()) {
        logout()
      }
    }

    history('/login')
  }

  async function editPsw(user){
    setLoading(true)
    try {
      await api.post('/users/change-password', user)
      toast.success('Senha alterada com sucesso!')
    } catch (error) {
      // tratar erro
      console.log(error)
      toast.error(error.response.data.message)
    } finally {
      setLoading(false)
    }
    
  }

  return { authenticated, loading, isAdmin, initials, register, login, logout, expired, editPsw }
}
